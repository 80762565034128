import React, { useEffect, useState } from 'react'
import { FlexGrid, Typography } from '@telus-uds/components-web'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import useKoodoAnalyticsEvent from '@/hooks/useKoodoAnalyticsEvent'
import Spacer from '../../core/Spacer/Spacer'

import {
  AccordionContainer,
  AccordionWrapper,
  AccordionNavigationIcon,
  AccordionCustomHeading,
  AccordionImageWrapper,
  AccordionImage,
} from './Styles'
import { TextRenderer } from '../../../utils/text/TextRenderer'
import alloyEvents from '../../../utils/alloyAnalytics/accordion'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import { IImage } from 'contentful-types'

export type AccordionItemProps = {
  heading: string
  children?: React.ReactNode
  accordionItemImage: IImage
  analytics?: ContentfulAnalyticsEventType
  image: React.ReactNode
}

export type AccordionWithImageProps = {
  sysID: string
  entryTitle: string
  accordionItems: AccordionItemProps[]
  linkAnalyticsEvent?: ContentfulAnalyticsEventType
  reverseColumns?: boolean
  accordionHeading?: string
  vertImageAlign?: 'center' | 'flex-Start' | 'flex-End'
  subHeading?: string
}

const AccordionWithImage = ({
  sysID,
  entryTitle,
  accordionItems,
  reverseColumns = false,
  accordionHeading = '',
  subHeading = '',
  vertImageAlign = 'center',
}: AccordionWithImageProps) => {
  const [currentOpenIndex, setCurrentIndex] = useState(0)
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = React.useState(false)
  const [analytics, setAnalytics] = useState<ContentfulAnalyticsEventType>(null)

  useEffect(() => {
    setIsAnalyticsTriggered(true)
  }, [analytics])

  useKoodoAnalyticsEvent(analytics, isAnalyticsTriggered)

  const mapAccordionImages = () => {
    if (accordionItems.length > 0) {
      return accordionItems.map((item, itemIndex) => {
        return (
          <AccordionImage key={itemIndex} currentIndex={currentOpenIndex} imageIndex={itemIndex}>
            {item.image}
          </AccordionImage>
        )
      })
    }
  }

  const mapAccordionItems = (accordionItems: AccordionWithImageProps['accordionItems']) => {
    return accordionItems.map((item, itemIndex) => {
      const { analytics, heading, children } = item
      return (
        <AccordionItem
          key={itemIndex}
          uuid={itemIndex}
          data-testid="accordion-item-testid"
          onClick={() => {
            setAnalytics(analytics)
            if (currentOpenIndex !== itemIndex) alloyEvents.expand({ sysID, entryTitle, panelID: heading })
          }}
        >
          <AccordionItemHeading>
            <AccordionItemButton>
              <AccordionNavigationIcon className="accordion-navigation-icon" />
              {heading && (
                <Typography>
                  <AccordionCustomHeading className="accordion-custom-heading">
                    <TextRenderer>{heading}</TextRenderer>
                  </AccordionCustomHeading>
                </Typography>
              )}
            </AccordionItemButton>
          </AccordionItemHeading>

          <AccordionItemPanel>{children}</AccordionItemPanel>
        </AccordionItem>
      )
    })
  }

  const generateAccordion = () => {
    return (
      <>
        {accordionHeading && renderAccordionHeading()}
        <AccordionWrapper>
          <Accordion preExpanded={[currentOpenIndex]} onChange={(itemIndex) => setCurrentIndex(Number(itemIndex[0]))}>
            {mapAccordionItems(accordionItems)}
          </Accordion>
        </AccordionWrapper>
        <Spacer xs={8} />
      </>
    )
  }

  const renderAccordionHeading = () => {
    return (
      <>
        <Typography variant={{ size: 'h1', tag: 'h2' }}>{accordionHeading}</Typography>
        {subHeading && (
          <>
            <Spacer xs={3} />
            <Typography variant={{ size: 'large', colour: 'secondary' }}>{subHeading}</Typography>
          </>
        )}
        <Spacer xs={6} />
      </>
    )
  }

  return (
    <div data-testid="accordionWithImage-testid">
      <AccordionContainer>
        <FlexGrid gutter={false}>
          <FlexGrid.Col>
            <FlexGrid.Row mdReverse={reverseColumns} distribute={'between'}>
              <FlexGrid.Col xs={12} md={5.84}>
                {generateAccordion()}
              </FlexGrid.Col>

              <FlexGrid.Col xs={0} md={5.84}>
                <AccordionImageWrapper vertAlign={vertImageAlign}>{mapAccordionImages()}</AccordionImageWrapper>
              </FlexGrid.Col>
            </FlexGrid.Row>
          </FlexGrid.Col>
        </FlexGrid>
      </AccordionContainer>
    </div>
  )
}

export default AccordionWithImage
