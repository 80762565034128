import styled, { css } from 'styled-components'
import Palette from '@telus-uds/palette-allium/build/web/palette'

const COLOURS = {
  Green: Palette.color.greenAccessible,
  GreenSanFelix: Palette.color.greenSanFelix,
  GreenDarkFern: Palette.color.greenDarkFern,
  GreyCloud: Palette.color.greyCloud,
  GreyMystic: Palette.color.greyMystic,
  GreyCharcoal: Palette.color.greyCharcoal,
  GreyThunder: Palette.color.greyThunder,
  Transparent: Palette.color.transparent,
}

const FONTSIZES = {
  Font24: Palette.fontSize.size20,
}

export const AccordionContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const AccordionWrapper = styled.div`
  position: relative;
  justify-content: center;

  .accordion__item {
    position: relative;
    margin-bottom: 32px;

    :last-child {
      margin-bottom: 0;
    }

    .accordion__panel {
      padding-left: 24px;
      padding-top: 8px;
    }
  }

  :before {
    content: '';
    position: absolute;
    left: 1px;
    top: 10px;
    width: 0px;
    height: calc(100% - 22px);
    border-left: 4px dotted ${COLOURS.GreyMystic};
  }

  .accordion__button[aria-expanded='true'],
  .accordion__button[aria-selected='true'] {
    outline-style: none;

    .accordion-custom-heading {
      font-size: ${FONTSIZES.Font24};
      line-height: 28px;
      cursor: default;
      color: ${COLOURS.GreyThunder};
    }
    .accordion-navigation-icon {
      height: 100%;
      top: 0;
      border-radius: 4px;
      background: ${COLOURS.Green};
    }
  }

  .accordion__button[aria-expanded='false'],
  .accordion__button[aria-disable='false'] {
    cursor: pointer;

    &:hover {
      .accordion-navigation-icon {
        height: 100%;
        top: 0;
        border-radius: 4px;
        background: ${COLOURS.Green};
      }
    }

    &:active {
      outline-style: none;

      .accordion-navigation-icon {
        height: 100%;
        top: 0;
        border-radius: 4px;
        background: ${COLOURS.GreenSanFelix};
      }
    }

    &:focus {
      outline-style: none;

      .accordion-navigation-icon {
        height: 100%;
        top: 0;
        border-radius: 4px;
        background: ${COLOURS.Green};
      }

      .accordion-custom-heading {
        color: ${COLOURS.GreenSanFelix};

        &:before {
          border: 2px solid ${COLOURS.Green};
        }
      }
    }

    &:focus:active {
      outline-style: none;

      .accordion-navigation-icon {
        background: ${COLOURS.Green};
      }
      .accordion-custom-heading {
        color: ${COLOURS.GreenDarkFern};
      }
    }
  }
`

export const AccordionNavigationIcon = styled.div`
  width: 7px;
  height: 7px;
  position: absolute;
  top: 8px;
  left: 0;
  border-radius: 50%;
  background: ${COLOURS.GreyCloud};
  transition: all 0.2s linear 0s;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

export const AccordionCustomHeading = styled.div`
  position: relative;
  padding-left: 24px;
  transition: all 0.2s linear 0s;
  font-weight: 600;
  display: inline-block;
  color: ${COLOURS.GreyCharcoal};

  &:hover {
    color: ${COLOURS.GreenSanFelix};
  }

  &:active {
    color: ${COLOURS.GreenDarkFern};
  }

  &:before {
    content: '';
    position: absolute;
    left: -6px;
    top: -6px;
    width: 100%;
    height: 100%;
    padding: 4px 6px;
    border: 2px solid transparent;
    border-radius: 8px;
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }
`

type AccordionImageWrapperProps = {
  vertAlign: string
}

export const AccordionImageWrapper = styled.div<AccordionImageWrapperProps>`
  display: flex;
  justify-content: 'center';
  align-items: ${(props) => props.vertAlign};
  width: 100%;
  height: 100%;
`

type AccordionImageProps = {
  currentIndex: number
  imageIndex: number
}

export const AccordionImage = styled.div<AccordionImageProps>`
  width: 100%;
  height: auto;
  display: none;
  opacity: 0;
  transition: all 0.2s linear 0s;

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  ${(props) =>
    props.currentIndex === props.imageIndex &&
    css`
      display: block;
      opacity: 1;
    `};

  div {
    span {
      display: block !important;
    }
  }
`
