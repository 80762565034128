import { IAccordionWithImageFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'

import AccordionWithImage from './AccordionWithImage'

const ContentfulAccordionWithImage = withContentful<IAccordionWithImageFields>(AccordionWithImage, {
  sysID: (props) => props.sys.id,
  entryTitle: (props) => props.fields.entryTitle,
  accordionItems: (props) => {
    return props.fields.accordionItems.map((item) => ({
      heading: item.fields?.heading,
      children: <BlockRenderer block={item.fields?.components} />,
      accordionItemImage: item.fields?.accordionItemImage,
      analytics: item.fields?.linkAnalyticsEvent,
      image: <BlockRenderer block={item.fields?.accordionItemImage} />,
    }))
  },
  vertImageAlign: (props) => props.fields.vertImageAlign?.toLowerCase(),
})

export default ContentfulAccordionWithImage
